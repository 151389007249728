.login-page {
  font-family: Arial, sans-serif;
  font-size: 20px;
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.header {
  text-align: center;
  padding: 10px;
}

.header img {
  height: 60px;
  margin: 20px auto;
}

.content {
  padding: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
}


input[type="username"] {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

input[type="password"],
input[type="text"] {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 10px;
  padding-right: 40px; /* Espa�o para o �cone */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.icon-eye {
  position: absolute;
  right: 4%;
  top: 38%;
  transform: translateY(-50%);
  cursor: pointer;
}


button[type="submit"] {
  padding: 10px;
  width: 100%;
  height: 40px;
  background-color: #0072b1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
}

button[type="submit"]:hover {
  background-color: #0069a5;
}

.footer {
  text-align: center;
  margin-top: 20px;
  color: #666;
}
  
.footer p {
  margin-bottom: 10px;
}

.error {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.error.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error.alerta {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}