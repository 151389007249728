/* Sidebar Global Style 
vermelho claro bot�o #e53d3a
vermelho logo escuro #db3a37
Cinza Texto #747070
*/

.sidebar {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 300px;
    padding: 60px 0;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    overflow: hidden;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 100;
}

.sidebar.open {
    transform: translateX(0);
}

.closeIcon {
    position: absolute;
    top: 15px;
    left: 35px;
    color: var(--logo-color);
    cursor: pointer;
}

.closeIcon:hover {
    opacity: 0.9;
    scale: 1.1;
}

.sidebar a {
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeLink {
    background-color: #e53d3a !important;
    color: #fff !important;
}

.activeSubLink {
    background-color: #e53d3a !important;
    color: #fff !important;
}

.activeMenu {
    background-color: #d6d6d6 !important;
    color: #e53d3a !important;
}

.authContext {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login {
    padding: .5rem 1.5rem;
    background-color: var(--logo-color);
    color: #ffffff;
    border-radius: 15px;
    border-color: rgba(0, 0, 0, 0.5);
    border-width: 0.0cap;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.login:hover {
    scale: 1.05;
    color: #fff
}

.login:active {
    scale: 1;
}

.sublink {
    font-size: 15px;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkWithMenu {
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #333;
    border-radius: 1px;
}

.triangle {
    margin-left: 0px;
    /* Ajuste conforme necess�rio */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    /* Cor do tri�ngulo */
    transition: transform 0.3s ease;
}

.open {
    transform: rotate(180deg);
    /* Rotaciona para baixo quando o menu est� aberto */
}

/* Responsive Design ''''''992px''''''*/

@media(max-width: 900px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


}