.container-log {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
}

p {
  color: #555;
}

.input-container-log {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container-log label {
  padding: 10px;
  color: #555;
  font-weight: bold;
}

.input-container-log select {
  padding: 5px 10px;
  margin-left: 10px;
  border: 1px solid #999999;
  border-radius: 5px;
  width: 200px;
  height: 35px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}

.input-container-log button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #4085c5;
  color: white;
  cursor: pointer;
  font-size: 16px;
  height: 35px;
  width: 180px;
  margin-bottom: 10px;
}

.buttonlog:hover {
  background-color: #2f6496;
}

.log-container {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  white-space: pre-wrap;
  font-family: monospace;
  max-height: 300px; /* Altura m�xima do container */
  overflow-y: auto; /* Adiciona a barra de rolagem vertical */
}

.log-container p {
  margin: 5px 0;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}