*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --primary-color: #e53d3a;
  --secondary-color: #f1f1f1;
  --logo-color: #db3a37;
  --text-color: #747070;
}

body{
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
  background-color: #eee;
  background: url(https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) rgba(0, 0, 0, 0);
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.page{
  width: 100%;
  padding-top: 45vh;
  text-align: center;
}

.page h1{
  font-size: 2.5rem;
  color: var(--text-color);
}

.container {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.container h2{
  font-size: 1.5rem;
  color: var(--text-color);
}

.construction-container {
  max-width: 400px;
  margin: auto;
}

.construction-icon {
  font-size: 4em;
  margin-bottom: 20px;
}